<template>
  <div class="questionnaire-container container">
    <single-measure
      :custom-measure-id="details.measure_id"
      :current-page="auditionData && auditionData.page ? auditionData.page.id : 0"
      :audition="true"
      @loaded="setTitle"
      @customizationFinished="finish"
    />
  </div>
</template>

<script>
import SingleMeasure from '@/components/measures/SingleMeasure'
import SetPageTitle from '@/mixins/SetPageTitle'
import { mapGetters } from 'vuex'

export default {
  name: 'AuditionMeasureQuestionnaire',
  components: {
    SingleMeasure
  },
  computed: {
    ...mapGetters({
      details: 'measureLibraryDetail',
      auditionData: 'getAuditionData'
    })
  },
  methods: {
    finish () {
      this.$router.push({ name: 'AuditionMeasureReport', params: { measureId: this.$route.params.measureId } })
    },
    setTitle () {
      SetPageTitle(this.$route.name, { ShortName: this.details.measure_storage.abbreviation, ID: this.details.measure_id })
    }
  }
}
</script>
<style lang="scss">
.questionnaire-container {
  max-width: 800px;
  padding-top: 57px;
  .single-measure {
    background: white;
  }
}
</style>
